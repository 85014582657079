import './src/scss/theme.scss'
import('../../src/js/app')
require('owl.carousel')

$(document).ready(function () {
  $('.suggestion-slider .owl-carousel').owlCarousel({
    margin: 30,
    dots: true,
    nav: true,
    pagination: true,
    paginationNumbers: true,
    loop: true,
    navigation: true,
    navClass: ['prev', 'next'],
    items: 1,
    autoplay: true,
    autoplayHoverPause: true,
    navText: ["<i class='fa fa-chevron-left d-flex'></i>", "<i class='fa fa-chevron-right d-flex'></i>"]
  })
})
